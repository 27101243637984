/*@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,700;1,400;1,700&display=swap');*/
/*@import url('https://fonts.googleapis.com/css2?family=Actor&display=swap');*/
@import url('https://unpkg.com/leaflet@1.7.1/dist/leaflet.css');
@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@300;400;700&display=swap');

html {
    font-size: 10px;
}

body {
    margin: 0;
    font-family: "Kumbh Sans", "Roboto", "Helvetica", "Arial", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-container {
    width: 100%;
    height: 650px;


}

@media screen and (max-width: 768px) {
    .leaflet-container {
        height: 350px;
    }
}
